<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<QuickfolderModelsList :accounting-firm-id="accountingFirmId" />
		</v-flex>
		<v-navigation-drawer v-model="drawer" absolute max-width="100vw" right temporary width="600px">
			<v-layout column fill-height>
				<v-flex scroll-y>
					<QuickfolderModelForm v-model="quickfolderModel" @close="drawer = false" />
				</v-flex>
			</v-layout>
		</v-navigation-drawer>
	</v-layout>
</template>

<script>
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'QuickfoldersManagerContainer',
	components: {
		QuickfolderModelForm: () => ({
			component: import('@/components/Eurex/QuickfoldersManager/QuickfolderModelForm')
		}),
		QuickfolderModelsList: () => ({
			component: import('@/components/Eurex/QuickfoldersManager/QuickfolderModelsList')
		})
	},
	mixins: [QuickFoldersModuleGuard],
	data: function () {
		return {
			drawer: false,
			quickfolderModel: {}
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.OPEN_QUICKFOLDER_MODEL_FORM, action: this.openForm }]
		},
		openForm: function (quickfolderModel) {
			this.quickfolderModel = quickfolderModel
			this.drawer = true
		}
	}
}
</script>
